import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import WindowStore from "~/stores/WindowStore"

type State = {
  navExpanded: boolean
  chatExpanded: boolean
  enabled: boolean
}

const initialState: State = {
  navExpanded: true,
  chatExpanded: false,
  enabled: false,
}

class MobileLayoutStore extends Store<State> {
  constructor() {
    super(initialState)
    this.syncWith([WindowStore], () => {
      const {windowSize} = WindowStore.getState()
      this.setState((prevState) => ({
        enabled: windowSize.width < 841,
        navExpanded: prevState.navExpanded,
        chatExpanded: prevState.chatExpanded,
      }))
    })
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "MOBILE_LAYOUT_STATE_UPDATE":
        return this.handleMobileLayoutStateUpdate(action)
      default:
        return false
    }
  }

  private handleMobileLayoutStateUpdate({navExpanded, chatExpanded}: {navExpanded: boolean; chatExpanded: boolean}) {
    this.setState((prevState) => ({
      enabled: prevState.enabled,
      navExpanded: navExpanded ?? prevState.navExpanded,
      chatExpanded: chatExpanded ?? prevState.chatExpanded,
    }))
  }
}

export default new MobileLayoutStore()
