import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type WindowSize = {
  width: number
  height: number
}

type State = {
  focused: boolean
  windowSize: WindowSize
}

const initialState: State = {
  focused: true,
  windowSize: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
}

class WindowStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "WINDOW_FOCUS":
        return this.handleWindowFocus(action)
      case "WINDOW_RESIZED":
        return this.handleWindowResized()
      default:
        return false
    }
  }

  private handleWindowFocus({focused}: {focused: boolean}) {
    this.setState((prevState) => ({...prevState, focused}))
  }

  private handleWindowResized() {
    const windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    this.setState((prevState) => ({...prevState, windowSize}))
  }
}

export default new WindowStore()
